<template>
  <v-card>
    <v-card-title class="py-1">{{ $t('approvalHours') }}</v-card-title>
    <v-card-text>
     <v-row>
      <v-col class="d-flex" cols="12" sm="6" lg="3">
        <v-autocomplete :items="listOfResponsibleProjects" :item-text="(val) =>  val.number ? `${val.number} - ${val.name }` : `${val.name}`" item-value="_id" dense
          :label="$t('selectProjects')" hide-details outlined v-model="filterObj.projects" multiple>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption">(+{{ filterObj.projects.length - 1 }} {{$t('others')}})</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6" lg="3">
        <v-autocomplete :items="getUsers" :item-text="(val) => `${val.name || ''}`" item-value="_id" :label="$t('selectEmployees')" hide-details outlined dense
          v-model="filterObj.users" multiple>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption">(+{{ filterObj.users.length - 1 }} {{$t('others')}})</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6" lg="2">
        <v-menu v-model="startMenu" :close-on-content-click="false" ransition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field clearable readonly v-on="on" hide-details v-model="filterObj.start" outlined @click:clear="$nextTick(() => startPicker = null)" :label="$t('startDate')" dense></v-text-field>
          </template>
          <v-date-picker no-title v-model="startPicker"  @input="startMenu = false" color="primary"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6" lg="2">
        <v-menu v-model="endMenu" :close-on-content-click="false" ransition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field clearable readonly v-on="on" hide-details v-model="filterObj.end" outlined @click:clear="$nextTick(() => startPicker = null)" :label="$t('endDate')" dense></v-text-field>
          </template>
          <v-date-picker no-title v-model="endPicker"  @input="endMenu = false" color="primary"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex pr-5" cols="12" sm="6" lg="2">
         <v-btn fab dark small color="primary" class="mb-7" @click="filterHours()">{{$t('go')}}</v-btn>
      </v-col>
      <!-- Tabs -->
      <v-col class="d-flex pr-5 pt-0" cols="12" sm="12" lg="12" @click="setApproveTab">
        <v-tabs v-model="active" dark slider-color="white" id="tab" centered fixed-tabs background-color="primary">
          <v-tab class="mt-2" @click="getHourList('delivered')">
            <v-icon color="white" small class="pr-2">mdi-clock</v-icon>
            <span class="hidden-sm-and-down">{{$t('approveHours')}}</span>
          </v-tab>
          <v-tab class="mt-2" @click="getHourList('approved')">
            <v-icon color="white" small class="pr-2">mdi-alarm-check</v-icon>
            <span class="hidden-sm-and-down">{{$t('approvedHours')}}</span>
          </v-tab>
          <v-tab class="mt-2" @click="getHourList('not_approved')">
            <v-icon color="white" small class="pr-2">mdi-alarm-off</v-icon>
            <span class="hidden-sm-and-down">{{$t('notApprovedHours')}}</span>
          </v-tab>
          <v-tab-item v-for="(item, index) in tabItems" :key="index">
          <v-card>
            <v-card-title>
              <v-row align="center">
                <v-col class="d-flex mr-auto" cols="12" sm="6" lg="2">
                  <v-btn color="success" class="mx-1" v-if="item.name == 'approveTab' || item.name == 'notApprovedTab'" @click="approveOrnotApproveHours()">{{$t('approve')}}</v-btn>
                  <v-btn color="error" class="mx-1" v-if="item.name == 'approveTab' || item.name == 'approvedTab'" @click="notApproveDialogHandler">{{$t('notApprove')}}</v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-data-table :headers="headers" :items="hoursList" show-select item-key="_id" v-model="selected" :must-sort='true' dense :loading="loading">
                  <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
                    <v-simple-checkbox :ripple="false" color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                  </template>
                  <template v-slot:[`item.notapprovedcomment`]="{ item }" v-if="active === 2">
                    {{ item.notapprovedcomment }}
                  </template>
                  <template v-slot:[`item.hours`]="{ item }">
                    {{ item.hours }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }" v-if="active !== 1">
                    <v-icon small class="mr-2" @click="getSingleEntryHandler(item._id)">mdi-pencil</v-icon>
                    <v-icon small @click="deleteEntryHandler(item._id)">mdi-delete</v-icon>
                  </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    </v-card-text>
    <!-- Dialog for not approve comments-->
    <v-dialog v-model="notApproveCommentsDialog" max-width="500px">
      <v-form ref="commentForm">
        <v-card>
          <v-card-title>
            {{$t('notApproved')}}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-textarea outlined textarea :label="$t('comments')" v-model="notApproveComments" :rules="$_requiredValidation"></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="notApproveCommentsHandler">{{$t('save')}}</v-btn>
            <v-btn color="error" @click.stop="notApproveCommentsDialog=false">{{$t('close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Dialog for edit-->
    <v-dialog  v-model="editHourDialog" max-width="700px">
      <form-template :references.sync="formReferences" :model="timerObj" ref="hourReference"></form-template>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import FormTemplate from '@/components/FormTemplate'
import CONSTANTS from '@/assets/json/constants.json'
export default {
  data () {
    return {
      MODULE: 'hours',
      listOfUsers: [],
      listOfResponsibleProjects: [],
      listOfTasks: [],
      startMenu: false,
      endMenu: false,
      userList: [],
      filterObj: {
        type: 'working'
        // start: this.$moment().startOf('month').format('DD.MM.YYYY'),
        // end: this.$moment().endOf('month').format('DD.MM.YYYY')
      },
      startPicker: '',
      endPicker: '',
      selected: [],
      hoursList: [],
      timerObj: {},
      search: '',
      loading: false,
      tabItems: [{
        name: 'approveTab'
      }, {
        name: 'approvedTab'
      }, {
        name: 'notApprovedTab'
      }],
      growValue: true,
      active: null,
      notApproveComments: '',
      notApproveCommentsDialog: false,
      editHourDialog: false,
      searchItem: '',
      skip: 0
    }
  },
  components: {
    FormTemplate
  },
  mounted () {
    this.active = parseInt(localStorage.getItem('currentApproveTab'))
    this.filterHours()
    const text = this.$t('deleteMsg')
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.HOURS) {
        const payload = { ids: data.ids, url: `${this.HOURS}/delete_multiple`, module: 'HOURS' }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.HOURS) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.filterHours()
      }
    })
  },
  methods: {
    getResponsibleProjects () {
      this.$api.execute('get', 'projects/get_current_projects')
        .then((response) => {
          this.listOfResponsibleProjects = this.$formatter.cloneVariable(response.data)
        })
    },
    // getTasksByProject (projectId) {
    //   this.$api.execute('get', `tasks/get_by_project/${projectId}`).then(response => {
    //     this.listOfTasks = response.data
    //   })
    // },
    getTasksByProject (projectId, skip, searchItem) {
      const limit = 30
      this.$api.execute('get', `tasks/get_by_project/${projectId}/${skip}/${limit}?searchTerm=${searchItem}`).then(response => {
        response.data.data.forEach(task => {
          this.listOfTasks.push(task.data)
          const userAssignedTasks = this.listOfTasks.filter(x => x.assigned_to === this.userId)
          this.listOfTasks = this.$formatter.cloneVariable(userAssignedTasks)
        })
      })
    },
    setApproveTab () {
      localStorage.setItem('currentApproveTab', this.active)
    },
    filterHours () {
      switch (this.active) {
        case 0:
          this.getHourList('delivered')
          break
        case 1:
          this.getHourList('approved')
          break
        case 2:
          this.getHourList('not_approved')
          break
        default:
          this.getHourList('delivered')
          break
      }
    },
    getHourList (type) {
      this.loading = true
      let model = this.filterObj
      const transformDate = { date: { list: ['start', 'end'], from: this.userDetails.dateformat, to: 'YYYY-MM-DDT23:59:59' } }
      model = this.$formatter.formatModel(model, transformDate)
      model.state = type
      if (!model.projects) model.projects = []
      model.for_approval = true
      this.$api.execute('post', `${this.MODULE}/filter`, model)
        .then((response) => {
          const transformData = { date: { list: ['date'], from: 'DD.MM.YYYYTHH:mm:ss', to: this.userDetails.dateformat }, number: { list: ['hours'], format: 'replaceDotWithComma' } }
          const list = this.$formatter.formatListModel(response.data, transformData)
          this.hoursList = list
          this.loading = false
        })
    },
    notApproveCommentsHandler () {
      if (this.$refs.commentForm.validate()) {
        this.approveOrnotApproveHours()
      }
    },
    approveOrnotApproveHours () {
      const model = { ids: [] }
      if (this.notApproveComments === '') {
        model.parameters = { state: 'approved' }
      } else {
        model.parameters = { state: 'not_approved', notapprovedcomment: this.notApproveComments }
      }
      if (this.selected.length !== 0) {
        for (let i = 0; i < this.selected.length; i++) {
          model.ids.push(this.selected[i]._id).toString()
        }
        this.$api.execute('put', `${this.MODULE}/bulk_update`, model).then(response => {
          this.filterHours()
          this.selected = []
          this.notApproveComments = ''
          this.notApproveCommentsDialog = false
        })
      } else {
        this.$root.$emit('snackbar', {
          snackbar: true,
          color: 'error',
          text: this.$t('selectEntries')
        })
        return false
      }
    },
    notApproveDialogHandler () {
      if (this.selected.length !== 0) {
        this.notApproveCommentsDialog = true
        this.notApproveComments = ''
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('selectEntries') })
      }
    },
    closeDialogHandler () {
      this.editHourDialog = false
      if (this.$refs && this.$refs.hourReference) this.$refs.hourReference.$refs.validateForm.reset()
    },
    getSingleEntryHandler (id) {
      this.$api.execute('get', `${this.MODULE}/${id}`).then(response => {
        let hour = response.data
        hour.hours = parseFloat(hour.hours).toFixed(2)
        hour.datePicker = null
        const transformNumber = { number: { list: ['hours'], format: 'replaceDotWithComma' } }
        hour = this.$formatter.formatModel(hour, transformNumber)
        const transformData = { date: { list: ['date'], from: 'DD.MM.YYYYTHH:mm:ss', to: this.userDetails.dateformat } }
        const model = this.$formatter.formatModel(hour, transformData)
        // let employee = this.getUsers.find(x => x._id === model.userid)
        // model.employee_name = employee && employee.name ? employee.name : ''
        if (response.data) this.timerObj = model
        this.editHourDialog = true
      })
    },
    updateHandler () {
      if (this.$refs.hourReference.$refs.validateForm.validate()) {
        this.loading = true
        let model = this.$formatter.cloneVariable(this.timerObj)
        model.state = model._id ? model.state : this.NOT_DELIVERED
        const transformNumber = { number: { list: ['hours'], format: 'replaceCommaWithDot' } }
        model = this.$formatter.formatModel(model, transformNumber)
        // const transformData = { date: { list: ['date'], from: 'DD.MM.YYYY', to: 'MM.DD.YYYY' } }
        // model = this.$formatter.formatModel(model, transformData)
        model.type = CONSTANTS.WORKING
        const url = this.MODULE
        this.$api.saveUpdateHandler(url, model)
          .then((response) => {
            // this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t('deliveredSuccessfully') })
            this.closeDialogHandler()
            this.filterHours()
          })
          .finally(() => {
            this.loading = false
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('validationIssue') })
    },
    deleteEntryHandler (id) {
      const ids = [id]
      const payload = { ids, url: `${this.MODULE}/delete_multiple`, module: this.HOURS }
      this.$store.commit('showDeleteDialog', payload)
    }
  },
  created () {
    this.getResponsibleProjects()
    this.$store.dispatch('getUsers')
    // this.$store.dispatch('getActivities')
    this.$store.dispatch('getWorktypes')
    this.filterObj.start = this.$moment().startOf('month').format(this.userDetails.dateformat)
    this.filterObj.end = this.$moment().endOf('month').format(this.userDetails.dateformat)
  },
  computed: {
    ...mapGetters(['formType', 'getWorktypes', 'getUsers', 'userDetails']),
    headers () {
      let headers = []
      headers = [{
        text: this.$t('project'),
        value: 'project_name'
      }, {
        text: this.$t('employee'),
        value: 'employee_name'
      }, {
        text: this.$t('date'),
        value: 'date'
      }, {
        text: this.$t('task'),
        value: 'task_name'
      }, {
        text: this.$t('hours'),
        value: 'hours'
      }, {
        text: this.$t('comments'),
        value: 'comments'
      }, {
        text: '',
        value: 'action',
        sortable: false
      }]
      if (this.active === 2) {
        headers.splice(5, 0, {
          text: this.$t('notApproved'),
          value: 'notapprovedcomment'
        })
      }
      return headers
    },
    formReferences () {
      return {
        // title: this.timerObj._id ? `${this.$t('editHours')} ${this.timerObj.employee_name ? `: Employee- ${this.timerObj.employee_name}` : ''}` : this.$t('addHours'),
        title: this.timerObj._id ? this.$t('editHours') : this.$t('addHours'),
        properties: [{
          model: 'projectid',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.listOfResponsibleProjects,
          select_text: (val) => `${val.name}`, // `${val.firstname || ''} ${val.lastname || ''}`,
          select_value: '_id',
          disabled: this.isReadOnly,
          label: this.$t('projects'),
          class: 'xs12'
        },
        // {
        //   model: 'userid',
        //   type: this.formType.AUTO_COMPLETE,
        //   rules: [],
        //   items: this.getUsers,
        //   is_list: false,
        //   click: (search) => false,
        //   term: '',
        //   disabled: this.isReadOnly,
        //   select_text: 'name',
        //   select_value: '_id',
        //   label: this.$t('user'),
        //   class: 'xs12'
        // },
        {
          model: 'worktypeid',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.getWorktypes,
          is_list: false,
          click: (search) => false,
          term: '',
          disabled: this.isReadOnly,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('worktype'),
          class: 'xs12'
        }, {
          model: 'date',
          type: this.formType.DATEPICKER,
          rules: this.$_requiredValidation,
          picker: 'datePicker',
          menu: 'dateMenu',
          color: 'primary lighten-1',
          label: '',
          disabled: this.isReadOnly,
          class: 'sm6 xs12'
        }, {
          model: 'hours',
          type: this.formType.TEXT,
          rules: this.$_HourValidation,
          label: this.$t('hours'),
          class: 'sm6 xs12'
        }, {
          model: 'taskid',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.listOfTasks,
          is_list: false,
          click: (search) => false,
          term: '',
          disabled: this.isReadOnly,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('task'),
          class: 'xs12'
        }, {
          model: 'comments',
          type: this.formType.TEXTAREA,
          rules: [],
          disabled: this.isReadOnly,
          label: this.$t('comments'),
          class: 'xs12'
        }, {
          model: 'notapprovedcomment',
          type: this.formType.TEXTAREA,
          rules: [],
          is_show: this.timerObj.state === CONSTANTS.NOT_APPROVED, // this.timerObj.state === CONSTANTS.NOT_APPROVED ? false : true,
          disabled: true,
          label: this.$t('notApproved'),
          class: 'xs12'
        }],
        buttons: [{
          name: 'action_handler',
          color: 'success',
          label: this.$t('update'),
          click: this.updateHandler,
          disabled: this.isReadOnly,
          loading: this.loading,
          is_show: true
        }, {
          name: 'close',
          color: 'info',
          click: this.closeDialogHandler,
          label: this.$t('close'),
          is_show: true
        }]
      }
    }
  },
  watch: {
    'startPicker' (val) {
      if (val) {
        this.filterObj.start = this.$formatter.formatDate(val, 'YYYY-MM-DD', this.userDetails.dateformat)
      }
    },
    'endPicker' (val) {
      if (val) {
        this.filterObj.end = this.$formatter.formatDate(val, 'YYYY-MM-DD', this.userDetails.dateformat)
      }
    },
    'timerObj.datePicker' (val) {
      if (val) {
        this.timerObj.date = this.$formatter.formatDate(val, 'YYYY-MM-DD', this.userDetails.dateformat)
      }
    },
    'timerObj.projectid' (val) {
      if (val) this.getTasksByProject(val, this.skip, this.searchItem)
      else this.listOfTasks = []
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
  }
}
</script>
